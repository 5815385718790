import React, { DetailedReactHTMLElement, ReactElement } from 'react';

interface PageErrorHandlerProps {
  props: {
    statusCode?: number;
    context404?: string;
  };

  children: any;
}
const Message = (props: any) => {
  return (
    <div className='flex h-full w-full items-center justify-center text-lg	 '>
      {props.message}
      <div>{props.children}</div>
    </div>
  );
};
const PageErrorHandler = (allProps: PageErrorHandlerProps) => {
  const { children, props } = allProps;

  if (props?.statusCode) {
    console.log(props?.statusCode);
    switch (props?.statusCode) {
      case 404:
        return <Message>{props?.context404}</Message>;
      case 401:
        return <Message>Please login</Message>;
      case 400:
        return <Message>Bad request</Message>;
      case 500:
        return <Message>Something went wrong</Message>;
      default:
        return <Message>Something went wrong</Message>;
    }
  } else {
    return React.cloneElement(children, { ...props });
  }
};

export default PageErrorHandler;
