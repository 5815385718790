import React, { useCallback } from 'react';
import Tooltip from '@/components/Tooltip';
import Info from '@/components/vectors/Info';
import AccountIcon from '@/components/vectors/AccountIcon';
import LogoutIcon from '../vectors/LogoutIcon';
import { COOKIES, deleteCookie, getCookieDomain } from '@/services/cookies';
import { useRouter } from 'next/router';
import shop101Logo from 'assets/Shop101.png';
import Image from 'next/image';
import { isKAM } from '@/utils/auth';
//create Header Component that has userInfo object as props
interface HeaderProps {
  storeName: string;
  supplierRating: number;
  isMobile: boolean;
}

const Header = (props: HeaderProps) => {
  const { storeName, supplierRating, isMobile } = props;

  const router = useRouter();
  const activePath = React.useMemo(() => router.asPath, [router.asPath]);

  const handleLogout = useCallback(() => {
    // removes domain specific cookies as well
    deleteCookie(null, COOKIES.shop101Session);
    deleteCookie(
      null,
      COOKIES.shop101Session,
      getCookieDomain(window.location.hostname)
    );
    router.replace('/login');
  }, [router]);

  if (isMobile) {
    return (
      <div className='navbar sticky top-0 z-30 flex !min-h-[3.8rem] w-full items-center justify-between border-b border-base-content/[0.1] bg-base-100 px-2.5 py-2 text-sm text-neutral shadow-md lg:px-4'>
        <a className='btn btn-link !min-h-8 !h-8 pl-0 text-xl normal-case text-secondary-content !no-underline'>
          {storeName}
        </a>

        {/* Right section */}
        <div className='flex'>
          <div className='dropdown dropdown-end'>
            <label
              tabIndex={0}
              className='btn btn-ghost !min-h-8 flex !h-8 items-center gap-0.5 pl-0.5 pr-0.5'
              onMouseDown={(e) => {
                const targetEl = e.currentTarget;
                if (targetEl && targetEl.matches(':focus')) {
                  setTimeout(function () {
                    targetEl.blur();
                  }, 0);
                }
              }}
            >
              <AccountIcon
                width={'28'}
                height={'28'}
              />
            </label>

            <ul
              tabIndex={0}
              className='dropdown-content menu rounded-box menu-compact w-auto bg-base-100 p-2 text-base-content shadow-[0_4px_15px_rgba(0,0,0,0.2)]'
            >
              {/*<li>*/}
              {/*  <a className="justify-between">*/}
              {/*    Profile*/}
              {/*    <span className="badge">New</span>*/}
              {/*  </a>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <a>Settings</a>*/}
              {/*</li>*/}
              <li onClick={handleLogout}>
                <a>Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='navbar sticky top-0 z-30 flex !min-h-[3.8rem] w-full items-center justify-between border-b border-base-content/[0.1] bg-base-100 px-4 py-2 text-sm text-neutral shadow-md'>
      {/* Left section */}
      <div className='flex-none lg:hidden'>
        <label
          htmlFor='sidebar'
          className='btn btn-square btn-ghost !min-h-8 !h-8 text-base-content'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            className='inline-block h-6 w-6 stroke-current'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M4 6h16M4 12h16M4 18h16'
            ></path>
          </svg>
        </label>
      </div>
      <div>
        <Image
          src={shop101Logo}
          alt={'shop101-logo'}
          loading='eager'
          width={30}
          height={30}
          className={'m-auto'}
        />
        <a className='btn btn-link !min-h-8 !h-8 text-xl normal-case text-secondary-content !no-underline'>
          Supplier Panel
        </a>
      </div>

      {/* Right section */}
      <div className='flex'>
        {/*<select data-choose-theme className="mr-4 w-16 text-ellipsis">*/}
        {/*  <option value="lofi">lofi</option>*/}
        {/*  <option value="cupcake">cupcake</option>*/}
        {/*  <option value="luxury">luxury</option>*/}
        {/*  <option value="light">light</option>*/}
        {/*  <option value="dark">dark</option>*/}
        {/*  <option value="bumblebee">bumblebee</option>*/}
        {/*  <option value="emerald">emerald</option>*/}
        {/*  <option value="corporate">corporate</option>*/}
        {/*  <option value="synthwave">synthwave</option>*/}
        {/*  <option value="retro">retro</option>*/}
        {/*  <option value="cyberpunk">cyberpunk</option>*/}
        {/*  <option value="valentine">valentine</option>*/}
        {/*  <option value="halloween">halloween</option>*/}
        {/*  <option value="garden">garden</option>*/}
        {/*  <option value="forest">forest</option>*/}
        {/*  <option value="aqua">aqua</option>*/}
        {/*  <option value="pastel">pastel</option>*/}
        {/*  <option value="fantasy">fantasy</option>*/}
        {/*  <option value="wireframe">wireframe</option>*/}
        {/*  <option value="black">black</option>*/}
        {/*  <option value="dracula">dracula</option>*/}
        {/*  <option value="cmyk">cmyk</option>*/}
        {/*  <option value="autumn">autumn</option>*/}
        {/*  <option value="business">business</option>*/}
        {/*  <option value="acid">acid</option>*/}
        {/*  <option value="lemonade">lemonade</option>*/}
        {/*  <option value="night">night</option>*/}
        {/*  <option value="coffee">coffee</option>*/}
        {/*  <option value="winter">winter</option>*/}
        {/*</select>*/}
        <div className='mr-5 flex items-center justify-center gap-2'>
          <div>Rating</div>
          <div className='badge badge-info h-7 rounded-full border-base-300 pl-2 pr-2'>
            <span className='text-base-content'>{supplierRating}</span>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9.30943 13.4086L12.8532 15.6586C13.3102 15.9469 13.8727 15.518 13.7391 14.9906L12.7126 10.9547C12.6848 10.8428 12.6892 10.7254 12.7253 10.6159C12.7614 10.5065 12.8276 10.4094 12.9165 10.336L16.0946 7.68517C16.5094 7.34064 16.2985 6.64455 15.7571 6.60939L11.6087 6.3422C11.4955 6.33562 11.3866 6.2962 11.2955 6.22878C11.2043 6.16135 11.1348 6.06883 11.0954 5.96252L9.5485 2.0672C9.50755 1.95464 9.43295 1.85739 9.33484 1.78868C9.23672 1.71996 9.11984 1.68311 9.00006 1.68311C8.88027 1.68311 8.76339 1.71996 8.66528 1.78868C8.56716 1.85739 8.49257 1.95464 8.45162 2.0672L6.90475 5.96252C6.86532 6.06883 6.79578 6.16135 6.70463 6.22878C6.61348 6.2962 6.50466 6.33562 6.39146 6.3422L2.24303 6.60939C1.70162 6.64455 1.49068 7.34064 1.90553 7.68517L5.08365 10.336C5.17248 10.4094 5.23875 10.5065 5.27483 10.6159C5.3109 10.7254 5.31532 10.8428 5.28756 10.9547L4.33834 14.6953C4.17662 15.3281 4.85162 15.8414 5.39303 15.4969L8.69068 13.4086C8.78316 13.3498 8.89047 13.3186 9.00006 13.3186C9.10964 13.3186 9.21696 13.3498 9.30943 13.4086Z'
                fill='#FFC53D'
              />
            </svg>
          </div>
          <Tooltip
            text={
              'Lower ratings impact your sales & how your product gets promoted'
            }
            orientation={'bottom'}
          >
            <Info />
          </Tooltip>
        </div>

        <div className='dropdown dropdown-end'>
          <label
            tabIndex={0}
            className='btn btn-ghost !min-h-8 flex !h-8 items-center gap-0.5 pl-0.5 pr-0.5'
            onMouseDown={(e) => {
              const targetEl = e.currentTarget;
              if (targetEl && targetEl.matches(':focus')) {
                setTimeout(function () {
                  targetEl.blur();
                }, 0);
              }
            }}
          >
            <AccountIcon
              width={'28'}
              height={'28'}
            />
            <div className='font-normal capitalize'>{storeName}</div>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4 6L8 10L12 6'
                stroke='#424242'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </label>

          <ul
            tabIndex={0}
            className='dropdown-content menu rounded-box menu-compact w-full bg-base-100 p-2 text-base-content shadow-[0_4px_15px_rgba(0,0,0,0.2)]'
          >
            {/*<li>*/}
            {/*  <a className="justify-between">*/}
            {/*    Profile*/}
            {/*    <span className="badge">New</span>*/}
            {/*  </a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <a>Settings</a>*/}
            {/*</li>*/}
            {!isKAM() && (
              <li onClick={() => router.replace('/profile/personaldetails')}>
                <a>
                  <div className='flex items-center'>
                    <div>
                      <AccountIcon
                        width={'20'}
                        height={'20'}
                      />
                    </div>
                    <div className='ml-[5px] mt-[2px]'>Profile</div>
                  </div>
                </a>
              </li>
            )}
            <li onClick={handleLogout}>
              <a>
                <div className='flex items-center'>
                  <div>
                    <LogoutIcon
                      width={'16'}
                      height={'16'}
                    />
                  </div>
                  <div className='ml-[5px] mt-[2px]'>Logout</div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        {/*<div className="dropdown dropdown-end">*/}
        {/*  <label tabIndex={0} className="btn btn-ghost btn-circle avatar">*/}
        {/*    <div className="rounded-full !flex items-center justify-between text-base-content">*/}
        {/*      <NotificationIcon />*/}
        {/*    </div>*/}
        {/*  </label>*/}
        {/*  <ul*/}
        {/*    tabIndex={0}*/}
        {/*    className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-200 text-base-content rounded-box w-52"*/}
        {/*  >*/}
        {/*    <li>*/}
        {/*      <a className="justify-between">*/}
        {/*        Profile*/}
        {/*        <span className="badge">New</span>*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <a>Settings</a>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <a>Logout</a>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default Header;
