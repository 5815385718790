import React, { useCallback } from 'react';
import {
  ToastContainer as Toast,
  toast,
  ToastPosition,
  ToastTransition,
  Slide,
  ToastClassName,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type ToastContainerProps = {
  autoClose?: number;
  position?: ToastPosition;
  transition?: ToastTransition;
};

const CloseButton = ({ closeToast }: { closeToast: any }) => (
  <div
    className='toast-message-close-button flex aspect-[2] items-center justify-center bg-black/[0.1] p-[11px] font-bold text-primary-content'
    onClick={closeToast}
  >
    &#10005;
  </div>
);

const ToastContainer: React.FC<ToastContainerProps> = ({
  autoClose,
  position,
  transition,
}) => {
  const getToastClass = useCallback(
    ({
      type,
      defaultClassName,
    }: {
      type: string;
      defaultClassName: string;
    }) => {
      let className = `${defaultClassName} !min-h-[35px] rounded-[6px] !p-0 `;

      switch (type) {
        case toast.TYPE.SUCCESS:
          className += '!bg-green-500';
          break;
        case toast.TYPE.ERROR:
        default:
          className += '!bg-red-600';
          break;
      }

      return className;
    },
    []
  );

  return (
    <Toast
      position={position}
      autoClose={autoClose}
      transition={transition}
      hideProgressBar
      newestOnTop
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover={false}
      icon={false}
      className={'!w-fit !min-w-[419px] !max-w-[510px]'}
      toastClassName={getToastClass as ToastClassName}
      bodyClassName='!text-primary-content !text-xs !font-bold !py-[8.5px] !px-[20px] leading-[15px]'
      closeButton={CloseButton}
    />
  );
};

ToastContainer.defaultProps = {
  autoClose: 5000,
  position: 'top-right',
  transition: Slide,
};

export default ToastContainer;
