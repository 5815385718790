import api, { paramsToString } from '@/utils/api';
import { sellerStoreId, userEmail } from '@/utils/auth';

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/stores`;

const getSlaStatuses = () => {
  return api.call({
    url: `${HOST}/slaStatuses`,
    method: 'get',
  });
};

const getPickupAddress = ({ storeId }: { storeId: string }) => {
  return api.call({
    url: `${HOST}/${storeId}/pickupAddresses`,
    method: 'get',
  });
};

const addPickupAddress = ({
  storeId,
  data,
}: {
  storeId: string;
  data: any;
}) => {
  return api.call({
    url: `${HOST}/${storeId}/pickupAddresses`,
    method: 'post',
    data: data,
  });
};

const updatePickupAddress = ({
  storeId,
  data,
}: {
  storeId: string;
  data: any;
}) => {
  return api.call({
    url: `${HOST}/${storeId}/pickupAddresses/${data.pickupLocationId}`,
    method: 'put',
    data: data,
  });
};

const deletePickupAddress = ({
  storeId,
  pickupLocationId,
}: {
  storeId: string;
  pickupLocationId: string;
}) => {
  return api.call({
    url: `${HOST}/${storeId}/pickupAddresses/${pickupLocationId}`,
    method: 'delete',
  });
};

const getAnnouncements = ({ limit, paginationKey }: any) =>
  api.call({
    method: 'get',
    url: `${HOST}/${sellerStoreId()}/announcement?${paramsToString({ limit, paginationKey })}`,
  });

export const markAllAnnouncementsAsRead = ({ announcementId }: any) =>
  api.call({
    method: 'post',
    url: `${HOST}/${sellerStoreId()}/announcementDisplayed/${announcementId}`,
  });

export const getLatestAnnouncementInfo = () =>
  api.call({
    method: 'get',
    url: `${HOST}/${sellerStoreId()}/announcementMetaData`,
  });

export const getCategories = () =>
  api.call({
    method: 'get',
    url: `${HOST}/${sellerStoreId()}/productCategories`,
  });

export const uploadBulkInventoryApi = ({ formData }: { formData: FormData }) =>
  api.call({
    method: 'post',
    data: formData,
    url: `${HOST}/${sellerStoreId()}/bulkInventorySheet`,
    responseType: 'blob',
  });

const setDefaultAutoShipmentApi = ({
  autoShipment,
}: {
  autoShipment: boolean;
}) => {
  return api.call({
    url: `${HOST}/${sellerStoreId()}/defaultAutoshipment?autoShipment=${autoShipment}`,
    method: 'post',
    headers: {
      'X-Auth-Email': userEmail(),
    },
  });
};

const approveTncApi = ({ tncId }: { tncId: number }) => {
  return api.call({
    url: `${HOST}/${sellerStoreId()}/acceptTnC?tncDocumentId=${tncId}`,
    method: 'post',
    headers: {
      'X-Auth-Email': userEmail(),
    },
  });
};

//update gst details api
const updateGstDetails = async ({ signal, ...data }: any) => {
  return api.call({
    method: 'put',
    url: `${HOST}/${sellerStoreId()}/gstDetails`,
    data,
  });
};
//get bank details api
const getBankDetailsApi = async () => {
  return api.call({
    method: 'get',
    url: `${HOST}/${sellerStoreId()}/bankAccountDetails`,
  });
};
//update bank details api
const updateBankDetailsApi = ({ payload }: { payload: any }) => {
  const storeId = sellerStoreId();
  const data = { ...payload, storeId };

  return api.call({
    method: 'put',
    url: `${HOST}/${storeId}/bankAccountDetails`,
    data,
  });
};
//this is the get GstDetails Api
const getGSTDetailsApi = () => {
  return api.call({
    method: 'get',
    url: `${HOST}/${sellerStoreId()}/gstDetails`,
  });
};

export default {
  getPickupAddress,
  addPickupAddress,
  updatePickupAddress,
  deletePickupAddress,
  getSlaStatuses,
  getAnnouncements,
  markAllAnnouncementsAsRead,
  getLatestAnnouncementInfo,
  getCategories,
  uploadBulkInventoryApi,
  setDefaultAutoShipmentApi,
  approveTncApi,
  updateGstDetails,
  getBankDetailsApi,
  updateBankDetailsApi,
  getGSTDetailsApi,
};
