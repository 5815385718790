const ReportsIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 9h6m-6 3h6m-6 3h3m2.69 5.25H4.5a.75.75 0 0 1-.75-.75v-15a.75.75 0 0 1 .75-.75h15a.75.75 0 0 1 .75.75v10.19a.742.742 0 0 1-.216.526l-4.818 4.818a.74.74 0 0 1-.525.216v0Z'
        stroke='#0A0A0A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.184 15H15v5.184'
        stroke='#0A0A0A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ReportsIcon;
