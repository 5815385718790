const ReturnIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <path
      d='M7.48047 9.34692H2.98047V4.84692'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.16797 17.8313C7.32148 18.9857 8.79148 19.7721 10.392 20.091C11.9925 20.4099 13.6517 20.2469 15.1596 19.6227C16.6674 18.9984 17.9563 17.941 18.8631 16.5842C19.77 15.2273 20.254 13.632 20.254 12C20.254 10.368 19.77 8.7727 18.8631 7.41585C17.9563 6.059 16.6674 5.00158 15.1596 4.37735C13.6517 3.75313 11.9925 3.59014 10.392 3.90902C8.79148 4.22789 7.32148 5.0143 6.16797 6.16875L2.98047 9.34688'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default ReturnIcon;
