import React from 'react';
import CloseIcon from '@/components/vectors/CloseIcon';

interface Props {
  isOpen: boolean;
  closeModal?: () => void;
  onConfirm?: (data: any) => void;
  title?: string;
  text?: string;
  content?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  isLoading?: boolean;
  isConfirmDisabled?: boolean;
  hideClose?: boolean;
  hideFooter?: boolean;
}

function ConfirmationModal(props: Props) {
  const {
    isOpen,
    closeModal,
    onConfirm,
    title,
    text,
    content,
    confirmText,
    cancelText,
    isLoading,
    isConfirmDisabled,
    hideClose,
    hideFooter,
  } = props;
  // const {} = useConfirmationModalContext();
  return (
    <>
      <div className={`modal rounded ${isOpen ? 'modal-open' : ''}`}>
        <div className='modal-box w-fit max-w-[90%] p-0'>
          <div className={`flex items-center justify-between px-6 py-2`}>
            {title && <h3 className='text-lg font-bold'>{title}</h3>}
            {!hideClose && (
              <div
                onClick={closeModal}
                className='btn btn-ghost min-h-8 ml-auto h-2 p-1'
              >
                <CloseIcon />
              </div>
            )}
          </div>
          {title && <div className='divider m-0 h-[1px]' />}
          <div className='p-3'>
            {!!content && <div className='mb-3'>{content}</div>}
            {text && (
              <div className='text-[15px] font-normal leading-[18px] text-neutral-700'>
                <p dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            )}
          </div>
          {!hideFooter && (
            <div className='modal-action mt-2 border-t border-zinc-300 px-6 py-4'>
              {cancelText && (
                <button
                  onClick={closeModal}
                  className='btn btn-outline btn-sm gap-2 text-xs'
                >
                  {cancelText}
                </button>
              )}
              {confirmText && (
                <button
                  onClick={onConfirm}
                  className='btn btn-primary btn-sm gap-2 text-xs disabled:border disabled:border-stone-300 disabled:bg-zinc-100'
                  disabled={isLoading || isConfirmDisabled}
                >
                  {confirmText}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

ConfirmationModal.displayName = 'ConfirmationModal';

export default ConfirmationModal;
