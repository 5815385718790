const PenaltyIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 7.5V12.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.4219 3H8.57812C8.48074 2.99966 8.38424 3.01853 8.29415 3.05553C8.20406 3.09253 8.12216 3.14694 8.05312 3.21563L3.21562 8.05313C3.14693 8.12216 3.09253 8.20407 3.05553 8.29415C3.01853 8.38424 2.99966 8.48074 3 8.57813V15.4219C2.99966 15.5193 3.01853 15.6158 3.05553 15.7059C3.09253 15.7959 3.14693 15.8778 3.21562 15.9469L8.05312 20.7844C8.12216 20.8531 8.20406 20.9075 8.29415 20.9445C8.38424 20.9815 8.48074 21.0003 8.57812 21H15.4219C15.5193 21.0003 15.6158 20.9815 15.7058 20.9445C15.7959 20.9075 15.8778 20.8531 15.9469 20.7844L20.7844 15.9469C20.8531 15.8778 20.9075 15.7959 20.9445 15.7059C20.9815 15.6158 21.0003 15.5193 21 15.4219V8.57813C21.0003 8.48074 20.9815 8.38424 20.9445 8.29415C20.9075 8.20407 20.8531 8.12216 20.7844 8.05313L15.9469 3.21563C15.8778 3.14694 15.7959 3.09253 15.7058 3.05553C15.6158 3.01853 15.5193 2.99966 15.4219 3V3Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M12.375 16.125C12.375 16.3321 12.2071 16.5 12 16.5C11.7929 16.5 11.625 16.3321 11.625 16.125C11.625 15.9179 11.7929 15.75 12 15.75C12.2071 15.75 12.375 15.9179 12.375 16.125Z'
        fill='black'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  );
};

export default PenaltyIcon;
