import { useEffect } from 'react';
import withHOC from '@/utils/with-hoc';
import MenuItem from '@/components/Sidebar/MenuItem';
import AnnouncementIcons from '@/components/vectors/AnnouncementIcons';
import {
  AnnouncementPageProvider,
  useAnnouncementPageContext,
} from '@/providers/announcementProvider';

interface Props {
  openAnnouncementModal: (list: any[], callbackFn: Function) => void;
}

const AnnouncementMenuItem = ({ openAnnouncementModal }: Props) => {
  const {
    unreadAnnouncementData,
    getUnreadPostStatus,
    markAllAnnouncementsAsRead,
  } = useAnnouncementPageContext();

  useEffect(() => {
    getUnreadPostStatus((list: any[]) =>
      openAnnouncementModal(list, markAllAnnouncementsAsRead)
    );
  }, []);

  const handleClickAllAsRead = () => {
    if (unreadAnnouncementData?.length > 0) {
      markAllAnnouncementsAsRead(unreadAnnouncementData?.[0]?.postId);
    }
  };

  return (
    <div onClick={handleClickAllAsRead}>
      <MenuItem
        icon={<AnnouncementIcons />}
        text='Announcements'
        link={'/announcements'}
        hasUnreadAnnouncement={unreadAnnouncementData?.length > 0}
      />
    </div>
  );
};

AnnouncementMenuItem.displayName = 'AnnouncementMenuItem';

export default withHOC(AnnouncementPageProvider, AnnouncementMenuItem);
