const AccountIcon = ({ width, height }: { width: string; height: string }) => {
  return (
    <svg
      width={width || '32'}
      height={height || '32'}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z'
        fill='#F5F5F5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 20C18.7614 20 21 17.7614 21 15C21 12.2386 18.7614 10 16 10C13.2386 10 11 12.2386 11 15C11 17.7614 13.2386 20 16 20Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.97498 24.925C8.72736 23.443 9.87542 22.1983 11.2919 21.3289C12.7084 20.4594 14.3379 19.9992 16 19.9992C17.662 19.9992 19.2916 20.4594 20.708 21.3289C22.1245 22.1983 23.2726 23.443 24.025 24.925'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default AccountIcon;
