import React from 'react';
import MenuItem from '@/components/Sidebar/MenuItem';
import NestedMenuItem from '@/components/Sidebar/NestedMenuItem';
import AnnouncementMenuItem from '@/components/Sidebar/AnnouncementMenuItem';
import ReturnIcon from '@/components/vectors/ReturnIcon';
// import FaqIcon from "@/components/vectors/FaqIcon";
// import AnnouncementIcons from "@/components/vectors/AnnouncementIcons";
// import RtoIcon from "@/components/vectors/RtoIcon";
import ReportsIcon from '@/components/vectors/ReportsIcon';
import PaymentsIcon from '@/components/vectors/PaymentsIcon';
import ManageProductsIcon from '@/components/vectors/ManageProductsIcon';
// import GstReportsIcon from "@/components/vectors/GstReportsIcon";
import PenaltyIcon from '@/components/vectors/PenaltyIcon';
import PackagingIcon from '@/components/vectors/PackagingIcon';
import SupportIcon from '@/components/vectors/SupportIcon';
import { useRouter } from 'next/router';
// <div className="bg-gray-dark text-black text-primary-content w-64 flex-shrink-0 fixed h-screen pt-24 px-2.5 mt-[-60px]">
import BoxIcon from '@/components/vectors/BoxIcon';
import SettingsIcon from '@/components/vectors/SettingsIcon';
import { isKAM } from '@/utils/auth';
import OrderIcon from '@/components/vectors/OrderIcon';
import roposoIcon from 'assets/roposo.png';
import cloutLogo from 'assets/clout.svg';
import AccountIcon from '@/components/vectors/AccountIcon';
import FaqIcon from '../vectors/FaqIcon';
import ConfirmationModal from '@/components/ConfirmationModal';

interface SidebarProps {
  openAnnouncementModal: (list: any[], callbackFn?: Function) => void;
  newOrdersInfo: any;
  setNewOrdersInfo: (state: any) => void;
}
const ordersNavMenuConfig = {
  id: '/orders',
  label: 'Manage Orders',
  icon: <OrderIcon />,
  navId: 'manage-orders-nav-group',
  childNavs: [
    {
      id: `/orders/ShipStreak`,
      label: 'Clout Orders',
      link: `/orders/ShipStreak/newpending`,
      icon: cloutLogo,
      navId: 'shipstreakOrders',
    },
    {
      id: `/orders/Roposo`,
      label: 'Roposo Orders',
      link: `/orders/Roposo/newpending`,
      icon: roposoIcon,
      navId: 'roposoOrders',
    },
  ],
};
// <div className="bg-gray-dark text-black text-white w-64 flex-shrink-0 fixed h-screen pt-24 px-2.5 mt-[-60px]">
const Sidebar: React.FC<SidebarProps> = ({
  openAnnouncementModal,
  newOrdersInfo,
  setNewOrdersInfo,
}) => {
  const router = useRouter();
  const activePath = React.useMemo(() => router.asPath, [router.asPath]);
  const [showTnCModal, setShowTnCModal] = React.useState(false);

  const orderMetaRenderer = (originType: string) => {
    if (newOrdersInfo?.[originType]) {
      return (
        <div className='relative'>
          <span className='font-normal'>({newOrdersInfo?.[originType]})</span>
          <span className='absolute -left-2 -top-0.5 flex h-1.5 w-1.5'>
            <span className='absolute inline-flex h-full w-full animate-ping rounded-full bg-red-200'></span>
            <span className='relative inline-flex h-1.5 w-1.5 rounded-full bg-red-500'></span>
          </span>
        </div>
      );
    }
    return null;
  };

  const menuItemClick = (originType: string) => {
    setNewOrdersInfo((prevState: any) => ({
      ...prevState,
      [originType]: false,
    }));
  };
  return (
    <>
      <div className='relative h-[calc(100%_-_60px)] w-[260px] overflow-hidden bg-base-200 shadow-[inset_-2px_0_2px_rgba(0,0,0,0.1)]'>
        <div className='h-full overflow-y-scroll'>
          <div className='w-[260px] overflow-y-scroll bg-base-200 shadow-[inset_-2px_0_2px_rgba(0,0,0,0.1)]'>
            <ul className='menu mb-10 w-full px-2.5 py-5 text-base-content'>
              <NestedMenuItem
                key={`orders-navgroup`}
                showFullWidthNavbar
                navGroup={ordersNavMenuConfig}
                metaInfoRenderer={orderMetaRenderer}
                menuItemClick={menuItemClick}
              />
              <MenuItem
                isActive={activePath.includes('/rto-returns')}
                icon={<ReturnIcon />}
                text='Manage RTO / Returns'
                link={'/rto-returns'}
              />
              <MenuItem
                isActive={activePath.includes('/listings')}
                icon={<BoxIcon />}
                text='Product Requirements'
                link={'/listings'}
              />
              {/*<MenuItem icon={<RtoIcon />} text="Manage RTO / Returns" />*/}
              <MenuItem
                isActive={activePath.includes('/manage-products')}
                icon={<ManageProductsIcon />}
                text='Manage Products'
                link={
                  isKAM()
                    ? '/seller/products/all-products/in-stock'
                    : '/manage-products'
                }
              />
              <MenuItem
                isActive={activePath.includes('/reports')}
                icon={<ReportsIcon />}
                text='Reports'
                link={'/reports'}
              />
              <MenuItem
                icon={<PaymentsIcon />}
                text='Payments'
                link='/payments'
              />
              {/* <MenuItem icon={<GstReportsIcon/>}
                      text="GST Reports"
                      link={'/seller/download-gst-report'}/> */}
              <MenuItem
                icon={<PenaltyIcon />}
                text='Penalties'
                link={'/penalties'}
                target={'_blank'}
              />
              <MenuItem
                icon={<PackagingIcon />}
                text='Packaging'
                link={process.env.NEXT_PUBLIC_PACKAGING_URL}
                target={'_blank'}
              />
              {/*<hr className="mb-2.5" />*/}
              <div className='divider mb-2.5 mt-1' />

              {!isKAM() && (
                <MenuItem
                  isActive={activePath.includes('/profile')}
                  icon={
                    <AccountIcon
                      width={'28'}
                      height={'28'}
                    />
                  }
                  text='Profile'
                  link={'/profile'}
                  isNew
                />
              )}

              <MenuItem
                isActive={activePath.includes('/settings')}
                icon={<SettingsIcon />}
                text='Settings'
                link={'/settings'}
                isNew
              />
              <AnnouncementMenuItem
                openAnnouncementModal={openAnnouncementModal}
              />
              <MenuItem
                icon={<FaqIcon />}
                text='FAQs'
                link={'/assets/Faq-On-Gst.pdf'}
                target={'_blank'}
                isNew
              />
              {/*<MenuItem icon={<FaqIcon/>}*/}
              {/*          text="FAQs"*/}
              {/*          link={'/seller/supplier-faq'}/>*/}
              <MenuItem
                icon={<SupportIcon />}
                text='Support'
                link={process.env.NEXT_PUBLIC_FRESHDESK_URL}
                target={'_blank'}
              />
            </ul>
          </div>
          <div className='absolute bottom-0 left-0 w-[99%] self-end bg-base-200 px-[10px] py-[22px] text-center text-xs text-neutral'>
            <a
              className='cursor-pointer underline'
              onClick={() => setShowTnCModal(true)}
            >
              <b>T&C</b> and <b>Privacy Policy</b> links
            </a>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title='TnC and Privacy Policy links'
        isOpen={showTnCModal}
        closeModal={() => setShowTnCModal(false)}
        hideFooter
        content={
          <div className='w-[600px] px-3 text-center'>
            <div>
              Roposo B2C{' '}
              <a
                className='font-semibold underline'
                target='_blank'
                href='https://www.shop101.com/static/termsOfService.html'
                rel='noreferrer'
              >
                T&C
              </a>{' '}
              and{' '}
              <a
                className='font-semibold underline'
                target='_blank'
                href='https://www.shop101.com/static/privacyPolicy.html'
                rel='noreferrer'
              >
                Privacy Policy
              </a>
              .
            </div>
            <div>
              Roposo Clout for Business{' '}
              <a
                className='font-semibold underline'
                target='_blank'
                href='https://www.shop101.com/static/b2bTermsOfService.html'
                rel='noreferrer'
              >
                T&C
              </a>
            </div>
            <div>
              Roposo B2C{' '}
              <a
                className='font-semibold underline'
                target='_blank'
                href='https://www.shop101.com/static/b2cSupplierGuidelines.html'
                rel='noreferrer'
              >
                Supplier Guidelines
              </a>
            </div>
          </div>
        }
      />
    </>
  );
};

export default Sidebar;
