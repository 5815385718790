import { useMemo, useState, useCallback, useRef } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import generateContext, { UseGetContextValue } from '@/utils/generate-context';
import useAsync from '@/hooks/use-async';
import StoresApi from '@/apis/store';

const DEFAULT_PAGINATION_KEY = -10000000;
const DEFAULT_LIMIT = 10;

export const useAnnouncementPage = () => {
  const getUnreadAnnouncementStatusQuery = useAsync(
    StoresApi.getLatestAnnouncementInfo
  );
  const markAllAnnouncementAsReadQuery = useAsync(
    StoresApi.markAllAnnouncementsAsRead
  );
  const getAnnouncementsQuery = useAsync(StoresApi.getAnnouncements);

  const [unreadAnnouncementData, setUnreadAnnouncementData] = useState<any[]>(
    []
  );
  const paginationKey = useRef(DEFAULT_PAGINATION_KEY);
  const [postData, setPostData] = useState<any[]>([]);

  const getUnreadPostStatus = useCallback((callback?: Function) => {
    getUnreadAnnouncementStatusQuery.execute(null, {
      onSuccess: (res: AxiosResponse<any>) => {
        if (res.data?.length > 0) {
          callback?.(res.data);
        }
        setUnreadAnnouncementData(res.data || []);
      },
      onError: () => null,
    });
  }, []);

  const markAllAnnouncementsAsRead = useCallback(
    (announcementId: number | string) => {
      markAllAnnouncementAsReadQuery.execute(
        { announcementId },
        {
          onSuccess: () => {
            setUnreadAnnouncementData([]);
          },
          onError: () => {
            setUnreadAnnouncementData([]);
          },
        }
      );
    },
    []
  );

  const getAnnouncementData = useCallback(
    (checkScrollBottom: any) => {
      getAnnouncementsQuery.execute(
        { limit: DEFAULT_LIMIT, paginationKey: paginationKey.current },
        {
          onSuccess: (res: AxiosResponse<any>) => {
            const announcementList = (res.data || []).map((post: any) => ({
              ...post,
              postDate: format(new Date(post.postDate), 'dd MMM yyyy'),
            }));
            setPostData((prevState) => [...prevState, ...announcementList]);
            paginationKey.current =
              announcementList?.length > 0
                ? res.data[res.data?.length - 1]?.postId
                : res.data?.length === 0
                  ? null
                  : paginationKey;
            if (announcementList?.length < DEFAULT_LIMIT) {
              document
                .getElementById('scrollableDiv')
                ?.removeEventListener('scroll', checkScrollBottom, true);
            }
          },
          onError: (e: AxiosError<any>) => {
            toast.error(e?.response?.data.status);
          },
        }
      );
    },
    [paginationKey, getAnnouncementsQuery]
  );

  return useMemo(
    () => ({
      paginationKey: paginationKey.current,
      isLoading: getAnnouncementsQuery.isPending,
      postData,
      getAnnouncementData,
      unreadAnnouncementData,
      getUnreadPostStatus,
      markAllAnnouncementsAsRead,
    }),
    [
      paginationKey,
      getAnnouncementsQuery.isPending,
      postData,
      getAnnouncementData,
      unreadAnnouncementData,
      getUnreadPostStatus,
      markAllAnnouncementsAsRead,
    ]
  );
};

export const [AnnouncementPageProvider, useAnnouncementPageContext] =
  generateContext(useAnnouncementPage as unknown as UseGetContextValue);

export default { AnnouncementPageProvider, useAnnouncementPageContext };
