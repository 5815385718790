import CloseIcon from '@/components/vectors/CloseIcon';
import AnnouncementPost from 'components/AnnouncementPost';
import { useState } from 'react';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  announcements?: any[];
}

function AnnouncementModal(props: Props) {
  const { isOpen, closeModal, announcements = [] } = props;
  const [activeIdx, setActiveIdx] = useState(0);

  if (!(isOpen && announcements?.length)) {
    return null;
  }

  return (
    <>
      <article className='modal modal-open rounded'>
        <div className='modal-box m-0 h-fit min-h-fit w-fit max-w-fit !scale-[0.875] p-0'>
          <div className='flex items-center justify-between px-4 py-2'>
            <p className='text-[18px] font-semibold leading-5'>Announcements</p>
            <div
              className='btn btn-ghost min-h-8 h-2 justify-self-end p-1'
              onClick={closeModal}
            >
              <CloseIcon />
            </div>
          </div>
          <div className='divider m-0 h-[1px]' />
          <section className='p-4'>
            <AnnouncementPost {...announcements[activeIdx]} />
          </section>

          {announcements?.length > 1 ? (
            <>
              <div className='divider m-0 h-[1px]' />

              <section className='flex items-center justify-between px-4 py-2'>
                <button
                  onClick={() => setActiveIdx((prev) => prev - 1)}
                  className='btn btn-primary btn-sm text-xs capitalize'
                  disabled={activeIdx === 0}
                >
                  Back
                </button>

                <span className='text-base font-medium !leading-5'>
                  {activeIdx + 1}/{announcements?.length}
                </span>

                <button
                  onClick={() => setActiveIdx((prev) => prev + 1)}
                  className='btn btn-primary btn-sm text-xs capitalize'
                  disabled={activeIdx === announcements?.length - 1}
                >
                  Next
                </button>
              </section>
            </>
          ) : null}
        </div>
      </article>
    </>
  );
}

AnnouncementModal.displayName = 'AnnouncementModal';

export default AnnouncementModal;
