import { FC, ReactNode } from 'react';

interface TooltipProps {
  text: string;
  orientation?: string;
  children: ReactNode;
}

const orientationToClassMapping: { [key: string]: string } = {
  top: 'tooltip-top',
  bottom: 'tooltip-bottom',
  left: 'tooltip-left',
  right: 'tooltip-right',
};

const Tooltip: FC<TooltipProps> = ({ text, orientation = 'top', children }) => {
  return (
    <div
      className={`tooltip ${orientationToClassMapping[orientation]} before:max-w-[200px] before:text-left before:text-xs`}
      data-tip={text}
    >
      {children}
    </div>
  );
};

export default Tooltip;
