import React, { useState, useEffect } from 'react';
import DownArrow from '../vectors/DownArrow';
interface Props {
  open?: boolean;
  replaceHeaderWithContent?: boolean;
  wrapperClass?: string;
  openWrapperClass?: string;
  containerClass?: string;
  renderHeader: React.ReactNode;
  renderContent: React.ReactNode;
  onToggle?: (value: boolean) => void;
}

const accordionHiddenClass = 'max-h-0 overflow-hidden opacity-0 hidden';
const accordionActiveClass =
  'inline-block max-h-full overflow-auto opacity-1 bg-white';

const Accordion: React.FC<Props> = (props) => {
  const {
    open = false,
    replaceHeaderWithContent = false,
    wrapperClass = '',
    openWrapperClass = '',
    containerClass = '',
    renderHeader,
    renderContent,
    onToggle,
  } = props;
  const [isOpen, setIsOpen] = useState(open);

  const handleToggle = () => {
    setIsOpen((status) => !status);
  };

  useEffect(() => {
    onToggle?.(isOpen);
  }, [isOpen]);

  return (
    <article
      className={`flex w-full flex-col gap-[2.5px] ${wrapperClass} ${isOpen ? openWrapperClass : ''} rounded-10 p-0 hover:bg-white`}
    >
      <section
        className={`flex cursor-pointer select-none items-baseline justify-between gap-1 rounded-[4px] ${containerClass} p-2 hover:bg-white`}
        onClick={handleToggle}
      >
        <div className='flex items-center hover:font-semibold '>
          <div>
            {replaceHeaderWithContent && isOpen ? renderContent : renderHeader}
          </div>
          <div className='ml-[30px] min-w-[fit-content] pl-7 pt-1'>
            <div
              className={`transform transition-transform ${
                isOpen
                  ? 'rotate-180 transition-transform duration-300'
                  : 'rotate-0 transition-transform duration-300'
              } transition-max-h transition-opacity ease-out`}
              onClick={handleToggle}
            >
              {/* {isOpen ? <ArrowUpIcon/> : <ArrowDownIcon/>} */}
              {<DownArrow />}
            </div>
          </div>
        </div>
      </section>
      {!replaceHeaderWithContent ? (
        <section
          className={`${isOpen ? accordionActiveClass : accordionHiddenClass} transition-[max-height] ease-out`}
        >
          {renderContent}
        </section>
      ) : null}
    </article>
  );
};

Accordion.defaultProps = {
  open: false,
  replaceHeaderWithContent: false,
  wrapperClass: '',
  openWrapperClass: '',
  containerClass: '',
  renderHeader: <></>,
  renderContent: <></>,
};

export default Accordion;
