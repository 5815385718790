import React, {
  createContext,
  useContext,
  useMemo,
  ReactNode,
  FC,
} from 'react';

type Props = {
  children: ReactNode;
};

type ContextValue = {
  [key: string]: any;
};

type ProviderProps = Props & {
  [key: string]: any;
};

export type UseGetContextValue<P = {}> = FC<P>;

function generateHookName(baseName = ''): string {
  return `${baseName || 'this'}Context`;
}

function generateProviderName(baseName = ''): string {
  return `${baseName.split('use')?.[1] || 'this'}Provider`;
}

function generateContext<P = {}>(
  useGetContextValue: UseGetContextValue<P>
): [
  React.ComponentType<ProviderProps>,
  () => ContextValue,
  React.Context<ContextValue>,
] {
  const functionName =
    useGetContextValue.displayName || useGetContextValue.name;
  const hookName = generateHookName(functionName);
  const providerName = generateProviderName(functionName);
  const Context = createContext<ContextValue>({});

  const errorMessage = `${hookName} hook must be used within ${providerName}`;

  const Provider: React.FC<ProviderProps> = (props) => {
    const { children, ...restProps } = props;
    const contextValue: any = useGetContextValue(restProps as P);

    const value = useMemo(() => {
      return { ...restProps, ...contextValue };
    }, [contextValue, restProps]);

    return <Context.Provider value={value}>{children}</Context.Provider>;
  };

  const useThisContext = (): ContextValue => {
    const context = useContext(Context);

    if (context === undefined || Object.keys(context || {}).length === 0) {
      throw new Error(errorMessage);
    }

    return context;
  };

  return [Provider, useThisContext, Context];
}

export default generateContext;
