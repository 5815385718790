import Image from 'next/image';

interface Props {
  navId: string;
  postId: number;
  postTitle: string;
  postDate: string;
  postImageURL?: string | null;
  imageRedirectionUrl?: string | null;
  postText?: string | null;
  cta?: string | null;
  ctaRedirectionUrl?: string | null;
  postVideoURL?: string | null;
}

const AnnouncementPost = (props: Props) => {
  const {
    navId,
    postTitle,
    postDate,
    postImageURL,
    imageRedirectionUrl,
    postText,
    cta,
    ctaRedirectionUrl,
    postVideoURL,
  } = props;

  return (
    <article
      id={navId}
      className='announcement-update-post flex w-[760px] flex-col gap-5 rounded-[10px] border border-[#C2C2C2] p-[30px]'
    >
      {/* post header */}
      <div>
        <p className='text-[14px] leading-6 text-[#757575]'>{postDate}</p>
        <p className='announcement-post-title text-[18px] font-semibold leading-[26px] text-[#424242]'>
          {postTitle}
        </p>
      </div>

      <hr className='border-[#EDEDED]' />

      {/* post image section */}
      {postImageURL ? (
        <a
          // @ts-ignore
          href={imageRedirectionUrl}
          target='_blank'
          rel='noopener noreferrer'
          className={`image-redirection-link ${!imageRedirectionUrl ? 'pointer-events-none' : ''}`}
        >
          <Image
            src={postImageURL}
            width={700}
            height={350}
            alt='post-image-full'
          />
        </a>
      ) : null}

      {/* post video section */}
      {postVideoURL ? (
        <video controls>
          <source src={postVideoURL} />
        </video>
      ) : null}

      {/* post text section */}
      {postText ? (
        <>
          <p
            className='announcement-post-text text-[18px] leading-[24px] text-[#424242] [&>ol]:list-decimal [&>ol]:pl-8 [&>ul]:list-disc [&>ul]:pl-8'
            dangerouslySetInnerHTML={{
              __html: postText,
            }}
          />
          {cta && ctaRedirectionUrl ? (
            <a
              href={ctaRedirectionUrl}
              target='_blank'
              rel='noopener noreferrer'
              className='cta-redirection-link w-fit rounded-sm bg-[#000] px-7 py-3 text-[14px] font-bold text-[#fff] no-underline'
            >
              {cta}
            </a>
          ) : null}
        </>
      ) : null}
    </article>
  );
};

export default AnnouncementPost;
