import { SVGProps } from 'react';

export const PackagingIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M21 16.6219V7.37812C20.9993 7.2446 20.9634 7.11362 20.8959 6.99841C20.8284 6.88319 20.7317 6.78781 20.6156 6.72187L12.3656 2.08125C12.2545 2.01706 12.1284 1.98328 12 1.98328C11.8716 1.98328 11.7455 2.01706 11.6344 2.08125L3.38437 6.72187C3.26827 6.78781 3.1716 6.88319 3.10411 6.99841C3.03663 7.11362 3.00072 7.2446 3 7.37812V16.6219C3.00072 16.7554 3.03663 16.8864 3.10411 17.0016C3.1716 17.1168 3.26827 17.2122 3.38437 17.2781L11.6344 21.9187C11.7455 21.9829 11.8716 22.0167 12 22.0167C12.1284 22.0167 12.2545 21.9829 12.3656 21.9187L20.6156 17.2781C20.7317 17.2122 20.8284 17.1168 20.8959 17.0016C20.9634 16.8864 20.9993 16.7554 21 16.6219V16.6219Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.5938 14.2969V9.42188L7.5 4.40625'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.8969 6.99377L12.0844 12L3.10312 6.99377'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.0844 12L12 22.0125'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default PackagingIcon;
