import { ReactNode, FC } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import greenBubbleIcon from 'assets/green-bubble.svg';

interface MenuItemProps {
  icon: ReactNode;
  text: string;
  isActive?: boolean;
  link?: string;
  target?: string;
  isNew?: boolean;
  hasUnreadAnnouncement?: boolean;
}
const activeClasses = ['border-black', 'bg-white', 'font-bold'];
const activeClassesString = activeClasses.join(' ');
const hoverStyle = activeClasses.map((item) => `hover:${item}`).join(' ');
const activeStyle = activeClasses.map((item) => `active:${item}`).join(' ');

const MenuItem: FC<MenuItemProps> = ({
  icon,
  text,
  isActive,
  link,
  target,
  isNew,
  hasUnreadAnnouncement = false,
}) => {
  return (
    <li
      className={`mb-2.5 cursor-pointer flex-row rounded border-l-4 border-transparent text-sm text-accent 
    duration-100 ease-out 
    hover:border-accent hover:bg-white hover:font-semibold 
    active:border-accent active:bg-base-100 ${isActive ? 'border-accent bg-base-100 font-semibold' : null}`}
    >
      <Link
        href={link || '#'}
        className='flex w-full gap-1 !rounded-none p-2 hover:!rounded-r hover:bg-white'
        target={target || ''}
      >
        <span>{icon}</span>
        <span>{text}</span>
        {isNew && (
          <span className='rounded-full bg-accent px-1.5 text-[10px] text-primary-content'>
            NEW
          </span>
        )}
        {link === '/announcements' && hasUnreadAnnouncement ? (
          <Image
            src={greenBubbleIcon}
            alt='update-bubble-icon'
          />
        ) : null}
      </Link>
    </li>
  );
};

export default MenuItem;
