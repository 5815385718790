import { format } from 'date-fns';
import api, { paramsToString } from '@/utils/api';
import { sellerStoreId } from '@/utils/auth';
import {
  ListingStatuses,
  OrderStatuses,
  filterKeyNameMapping,
} from '@/constants/enums';

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/suppliers`;

const getOrdersApi = ({
  paginationKey = null,
  limit = 10,
  status = OrderStatuses.newpending,
  filters = {},
  searchQuery = '',
  orderOrigin = '',
}: {
  paginationKey?: string | null;
  limit?: number;
  status?: string;
  filters?: { [key in keyof typeof filterKeyNameMapping]?: any };
  searchQuery?: string;
  orderOrigin: string;
}) => {
  let filterString = '';
  if (filters && Object.keys(filters).length > 0) {
    filterString = Object.keys(filters).reduce((acc, key) => {
      if (
        filters[key as keyof typeof filterKeyNameMapping] !== '' &&
        filters[key as keyof typeof filterKeyNameMapping] !== undefined
      ) {
        acc += `&${filterKeyNameMapping[key as keyof typeof filterKeyNameMapping]}=${
          key.includes('Date')
            ? format(
                filters[key as keyof typeof filterKeyNameMapping],
                'yyyy-MM-dd'
              )
            : encodeURIComponent(
                filters[key as keyof typeof filterKeyNameMapping]
              )
        }`;
      }
      return acc;
    }, '');
  }

  return api
    .call({
      url: `${HOST}/${sellerStoreId()}/orders/details?limit=${limit}&status=${status}${filterString}${searchQuery}${paginationKey ? `&paginationKey=${paginationKey}` : ''}&orderOrigin=${orderOrigin}`,
      method: 'get',
    })
    .then((res) => ({
      ...res,
      data: {
        ...res.data,
        orderCount: res.data.count,
        orders: res.data.list,
        paginationKey: res.data.paginationKey,
      },
    }));
};

const getAggOrdersApi = ({
  paginationKey = null,
  limit = 10,
  status = OrderStatuses.newpending,
  filters = {},
  searchQuery = '',
  orderOrigin,
}: {
  paginationKey?: string | null;
  limit?: number;
  status?: string;
  filters?: { [key in keyof typeof filterKeyNameMapping]?: any };
  searchQuery?: string;
  orderOrigin: string;
}) => {
  let filterString = '';
  if (filters && Object.keys(filters).length > 0) {
    filterString = Object.keys(filters).reduce((acc, key) => {
      if (
        filters[key as keyof typeof filterKeyNameMapping] !== '' &&
        filters[key as keyof typeof filterKeyNameMapping] !== undefined
      ) {
        acc += `&${filterKeyNameMapping[key as keyof typeof filterKeyNameMapping]}=${
          key.includes('Date')
            ? format(
                filters[key as keyof typeof filterKeyNameMapping],
                'yyyy-MM-dd'
              )
            : encodeURIComponent(
                filters[key as keyof typeof filterKeyNameMapping]
              )
        }`;
      }
      return acc;
    }, '');
  }

  return api
    .call({
      url: `${HOST}/${sellerStoreId()}/orders/aggregated?limit=${limit}&status=${status}${filterString}${searchQuery}${paginationKey ? `&paginationKey=${paginationKey}` : ''}&orderOrigin=${orderOrigin}`,
      method: 'get',
    })
    .then((res) => ({
      ...res,
      data: {
        ...res.data,
        orderCount: res.data.count,
        orders: res.data.aggregatedOrderDetailsList.map((order: any) => ({
          ...order,
          orderId: order.orderIds.toString(),
        })),
        paginationKey: res.data.paginationKey,
      },
    }));
};

const getAsyncDownloadsApi = ({
  paginationKey,
  limit = 10,
  downloadReferenceId,
  downloadType = '',
}: {
  paginationKey?: number | null;
  limit?: number;
  downloadReferenceId?: string;
  downloadType?: string;
}) => {
  return api.call({
    url:
      `${HOST}/${sellerStoreId()}/asyncDownloads?limit=${limit}` +
      (downloadReferenceId
        ? `&downloadReferenceId=${downloadReferenceId}`
        : '') +
      (downloadType ? `&downloadType=${downloadType}` : '') +
      (paginationKey ? `&paginationKey=${paginationKey}` : ''),
    method: 'get',
  });
};

const getHoldOrdersApi = () => {
  return api
    .call({
      url: `${HOST}/${sellerStoreId()}/orders/onhold`,
      method: 'get',
    })
    .then((res) => ({
      ...res,
      data: { ...res.data, orderCount: res.data.count, orders: res.data.list },
    }));
};

const getProductListingsApi = ({
  activeStatus,
  query = '',
  paginationKey,
  limit,
}: {
  activeStatus: string;
  query?: string;
  paginationKey?: number | null;
  limit?: number;
}) => {
  const params: { [key: string]: any } = {
    isNew: activeStatus === ListingStatuses.new,
    limit,
  };
  if (query) {
    params['query'] = query;
  }
  if (paginationKey) {
    params['paginationKey'] = paginationKey;
  }
  return api.call({
    url: `${HOST}/${sellerStoreId()}/newOrOOSProducts`,
    params,
    method: 'get',
  });
};

const setProductListingRequestApi = ({
  newOrOOSProductId,
}: {
  newOrOOSProductId: number;
}) => {
  return api.call({
    url: `${HOST}/${sellerStoreId()}/raiseRequest`,
    data: {
      newOrOOSProductId,
    },
    method: 'post',
  });
};

const getOrdersCountApi = () => {
  return api.call({
    url: `${HOST}/${sellerStoreId()}/orderCount`,
    method: 'get',
  });
};

const getOrdersAggregatedApi = ({
  paginationKey = null,
  limit = 10,
  status = OrderStatuses.newpending,
  filters = {},
  searchQuery = '',
}: {
  paginationKey?: string | null;
  limit?: number;
  status?: string;
  filters?: { [key in keyof typeof filterKeyNameMapping]?: any };
  searchQuery?: string;
}) => {
  let filterString = '';
  if (filters && Object.keys(filters).length > 0) {
    filterString = Object.keys(filters).reduce((acc, key) => {
      if (filters[key as keyof typeof filterKeyNameMapping]) {
        acc += `&${filterKeyNameMapping[key as keyof typeof filterKeyNameMapping]}=${
          key.includes('Date')
            ? format(
                filters[key as keyof typeof filterKeyNameMapping],
                'yyyy-MM-dd'
              )
            : encodeURIComponent(
                filters[key as keyof typeof filterKeyNameMapping]
              )
        }`;
      }
      return acc;
    }, '');
  }

  return api
    .call({
      url: `${HOST}/${sellerStoreId()}/orders/aggRTOAndReturns/${status}?limit=${limit}${filterString}${searchQuery}${paginationKey ? `&paginationKey=${paginationKey}` : ''}`,
      method: 'get',
    })
    .then((res) => ({
      ...res,
      data: {
        ...res.data,
        orderCount: res.data.count,
        orders: res.data.list,
        paginationKey:
          res.data.list.length < limit ? null : res.data.paginationKey,
      },
    }));
};

const getRtoReturnOrdersOverviewListApi = ({
  paginationKey = null,
  limit = 10,
  filters = {},
}: {
  paginationKey?: string | null;
  limit?: number;
  filters?: { [key in keyof typeof filterKeyNameMapping]?: any };
}) => {
  let filterString = '';
  if (filters && Object.keys(filters).length > 0) {
    filterString = Object.keys(filters).reduce((acc, key) => {
      if (filters[key as keyof typeof filterKeyNameMapping]) {
        acc += `&${filterKeyNameMapping[key as keyof typeof filterKeyNameMapping]}=${
          key.includes('Date')
            ? format(
                filters[key as keyof typeof filterKeyNameMapping],
                'yyyy-MM-dd'
              )
            : encodeURIComponent(
                filters[key as keyof typeof filterKeyNameMapping]
              )
        }`;
      }
      return acc;
    }, '');
  }

  return api
    .call({
      url: `${HOST}/${sellerStoreId()}/orders/aggRTOAndReturns/overviewList?limit=${limit}${filterString}${paginationKey ? `&paginationKey=${paginationKey}` : ''}`,
      method: 'get',
    })
    .then((res) => ({
      ...res,
      data: {
        ...res.data,
        orderCount: res.data.count,
        orders: res.data.list,
        paginationKey:
          res.data.list.length < limit ? null : res.data.paginationKey,
      },
    }));
};

const getRtoReturnOrdersOverviewApi = ({
  filters = {},
}: {
  filters?: { [key in keyof typeof filterKeyNameMapping]?: any };
}) => {
  let filterString = '';
  if (filters && Object.keys(filters).length > 0) {
    filterString = Object.keys(filters).reduce((acc, key) => {
      if (filters[key as keyof typeof filterKeyNameMapping]) {
        acc += `${filterKeyNameMapping[key as keyof typeof filterKeyNameMapping]}=${
          key.includes('Date')
            ? format(
                filters[key as keyof typeof filterKeyNameMapping],
                'yyyy-MM-dd'
              )
            : encodeURIComponent(
                filters[key as keyof typeof filterKeyNameMapping]
              )
        }&`;
      }
      return acc;
    }, '?');
  }
  return api.call({
    url: `${HOST}/${sellerStoreId()}/orders/aggRTOAndReturns/overview${filterString}`,
    method: 'get',
  });
};

const downloadOrdersDataApi = ({
  status = OrderStatuses.newpending,
  filters = {},
  searchQuery = '',
}: {
  status?: string;
  filters?: { [key in keyof typeof filterKeyNameMapping]?: any };
  searchQuery?: string;
}) => {
  let filterString = '';
  if (filters && Object.keys(filters).length > 0) {
    filterString = Object.keys(filters).reduce((acc, key) => {
      if (filters[key as keyof typeof filterKeyNameMapping]) {
        acc += `&${filterKeyNameMapping[key as keyof typeof filterKeyNameMapping]}=${
          key.includes('Date')
            ? format(
                filters[key as keyof typeof filterKeyNameMapping],
                'yyyy-MM-dd'
              )
            : encodeURIComponent(
                filters[key as keyof typeof filterKeyNameMapping]
              )
        }`;
      }
      return acc;
    }, '');
  }

  return api
    .call({
      url: `${HOST}/${sellerStoreId()}/orders/details/download?status=${status}${filterString}${searchQuery}`,
      method: 'get',
    })
    .then((res) => ({
      ...res,
      data: {
        ...res.data,
        orderCount: res.data.count,
        orders: res.data.list,
        paginationKey: res.data.paginationKey,
      },
    }));
};

const getAggOrdersShippersListApi = ({
  paginationKey = null,
  limit = 10,
  status = OrderStatuses.newpending,
  filters = {},
  searchQuery = '',
}: {
  paginationKey?: string | null;
  limit?: number;
  status?: string;
  filters?: { [key in keyof typeof filterKeyNameMapping]?: any };
  searchQuery?: string;
}) => {
  let filterString = '';
  if (filters && Object.keys(filters).length > 0) {
    filterString = Object.keys(filters).reduce((acc, key) => {
      if (
        filters[key as keyof typeof filterKeyNameMapping] !== '' &&
        filters[key as keyof typeof filterKeyNameMapping] !== undefined
      ) {
        acc += `&${filterKeyNameMapping[key as keyof typeof filterKeyNameMapping]}=${
          key.includes('Date')
            ? format(
                filters[key as keyof typeof filterKeyNameMapping],
                'yyyy-MM-dd'
              )
            : encodeURIComponent(
                filters[key as keyof typeof filterKeyNameMapping]
              )
        }`;
      }
      return acc;
    }, '');
  }

  return api.call({
    url: `${HOST}/${sellerStoreId()}/orders/shippersList?status=${status}${filterString}${searchQuery}`,
    method: 'get',
  });
};

export const downloadTableData = ({
  status = OrderStatuses.newpending,
  filters = {},
  searchQuery = '',
}: {
  status?: string;
  filters?: { [key in keyof typeof filterKeyNameMapping]?: any };
  searchQuery?: string;
}) => {
  let filterString = '';
  if (filters && Object.keys(filters).length > 0) {
    filterString = Object.keys(filters).reduce((acc, key) => {
      if (
        filters[key as keyof typeof filterKeyNameMapping] !== '' &&
        filters[key as keyof typeof filterKeyNameMapping] !== undefined
      ) {
        acc += `&${filterKeyNameMapping[key as keyof typeof filterKeyNameMapping]}=${
          key.includes('Date')
            ? format(
                filters[key as keyof typeof filterKeyNameMapping],
                'yyyy-MM-dd'
              )
            : encodeURIComponent(
                filters[key as keyof typeof filterKeyNameMapping]
              )
        }`;
      }
      return acc;
    }, '');
  }

  return api.call({
    url: `${HOST}/${sellerStoreId()}/orders/aggregated/download?status=${status}${filterString}${searchQuery}`,
    method: 'get',
  });
};

export const downloadPendingTableData = () => {
  return api.call({
    url: `${HOST}/${sellerStoreId()}/orders/pendingOrdersInfo/download`,
    method: 'get',
  });
};

const getPriceChangeSetApi = ({
  paginationKey = null,
  limit = 10,
}: {
  paginationKey?: string | null;
  limit?: number;
}) => {
  return api
    .call({
      url: `${HOST}/${sellerStoreId()}/priceChangeSet?limit=${limit}${paginationKey ? `&paginationKey=${paginationKey}` : ''}`,
      method: 'get',
    })
    .then((res) => ({
      ...res,
      data: {
        ...res.data,
        productCount: res.data.count,
        products: res.data.data.map((productObj: any) => ({
          ...productObj,
          productId: productObj.transferPriceId,
          productVariants: [
            {
              productVariantSKUId: productObj.sku,
            },
          ],
        })),
        paginationKey: res.data.paginationKey,
      },
    }));
};

const approveRejectPriceChangeApi = ({
  isApproved,
  transferPriceIdList,
}: {
  isApproved: boolean;
  transferPriceIdList: number[];
}) => {
  return api.call({
    url: `${HOST}/${sellerStoreId()}/priceChangeSet`,
    method: 'post',
    data: {
      isApproved,
      transferPriceIdList,
    },
  });
};

const bulkApprovePriceChangeApi = ({
  latestTransferPriceId,
}: {
  latestTransferPriceId: number;
}) => {
  return api.call({
    url: `${HOST}/${sellerStoreId()}/bulkApproval/priceChangeSet`,
    method: 'post',
    data: {
      latestTransferPriceId,
    },
  });
};

const fetchTncContentApi = ({ tncId }: { tncId: number }) => {
  return api.call({
    url: `${HOST}/${sellerStoreId()}/supplierTnc/${tncId}`,
    method: 'get',
  });
};

const acceptTncContentApi = ({ tncId }: { tncId: number }) => {
  return api.call({
    url: `${HOST}/${sellerStoreId()}/supplierTnc/${tncId}`,
    method: 'put',
  });
};

export const getGSTInvoiceApi = ({ signal, ...payload }: any) =>
  api.call({
    method: 'post',
    url: `${HOST}/${sellerStoreId()}/processInvoiceAndCreditNote?${paramsToString(payload)}`,
  });

const verifyEmailApi = ({ email = '' }: { email: string }) => {
  return api.call({
    url: `${HOST}/${sellerStoreId()}/triggerVerificationEmail?email=${email}`,
    method: 'get',
  });
};

const verifyEmailLinkApi = ({
  encryptedString = '',
}: {
  encryptedString: string;
}) => {
  return api.call({
    url: `${HOST}/${sellerStoreId()}/verifyEmail`,
    method: 'get',
    params: { encryptedString },
  });
};
//this is the personal details
export const getPersonalDetailsApi = () => {
  return api.call({
    method: 'get',
    url: `${HOST}/${sellerStoreId()}/supplierProfileDetails`,
  });
};
//this is the update Personal Details
export const updatePersonalDetails = ({
  userName = '',
  storeName = '',
  storeEmail = '',
}: {
  userName: string;
  storeName: string;
  storeEmail: string;
}) => {
  return api.call({
    method: 'post',
    url: `${HOST}/${sellerStoreId()}/supplierProfileDetails`,
    data: {
      userName,
      storeName,
      storeEmail,
    },
  });
};

export const verifySignupOtpApi = ({
  phoneNumber = '',
  password = '',
  email = '',
  otp = '',
  otpAuthToken = '',
}: {
  phoneNumber?: string;
  password?: string;
  email?: string;
  otp?: string;
  otpAuthToken?: string;
}) => {
  const headers: { [key: string]: string } = {};
  headers['otp'] = otp;
  headers['x-auth-token'] = otpAuthToken;
  return api.call({
    url: `${HOST}/register`,
    method: 'post',
    data: {
      storePhone: phoneNumber,
      storePasswordHash: password,
      storeEmail: email,
    },
    headers,
  });
};

export default {
  getOrdersApi,
  getAggOrdersApi,
  getAsyncDownloadsApi,
  getHoldOrdersApi,
  getProductListingsApi,
  setProductListingRequestApi,
  getOrdersCountApi,
  getOrdersAggregatedApi,
  getRtoReturnOrdersOverviewListApi,
  getRtoReturnOrdersOverviewApi,
  downloadOrdersDataApi,
  getAggOrdersShippersListApi,
  downloadTableData,
  downloadPendingTableData,
  getPriceChangeSetApi,
  approveRejectPriceChangeApi,
  bulkApprovePriceChangeApi,
  fetchTncContentApi,
  acceptTncContentApi,
  getGSTInvoiceApi,
  verifyEmailApi,
  verifyEmailLinkApi,
  getPersonalDetailsApi,
  updatePersonalDetails,
  verifySignupOtpApi,
};
