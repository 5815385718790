import '@/styles/globals.css';
import '@/styles/date-range.css';
import '@/styles/slick-carousel.css';
import type { AppContext, AppProps } from 'next/app';
import Layout from '@/components/Layout';
import React from 'react';
import App from 'next/app';
import logger from '@/utils/logger';
//create type definition for MyApp
type MyAppProps = AppProps & {
  Component: any;
  pageProps: any;
  props: {
    context404: string | null;
    statusCode: number | null;
    supplierRating: number;
  };
};

const MyApp = ({ Component, pageProps, props }: MyAppProps) => {
  return (
    <Layout props={{ ...pageProps, ...props }}>
      <Component
        {...props}
        {...pageProps}
      />
    </Layout>
  );
};

MyApp.getInitialProps = async (appCtx: AppContext): Promise<any> => {
  if (typeof window === 'undefined') {
    logger.info(
      `[Page][Request] GET ${appCtx.ctx.req?.url} ${JSON.stringify(appCtx.ctx.req?.headers)}`
    );
  }
  const appProps = await App.getInitialProps(appCtx);
  return appProps;
};

export default MyApp;
