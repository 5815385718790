import { COOKIES, getCookie, getCookieDomain } from 'services/cookies';
import { NextPageContext } from 'next';
import { captureException } from '@sentry/nextjs';

const isUserLoggedIn = (ctx?: Partial<NextPageContext> | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || '') : '';
  return !!getCookie(ctx, COOKIES.shop101Session, domain);
};

const userId = (ctx?: Partial<NextPageContext> | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || '') : '';
  return getCookie(ctx, COOKIES.shop101Session, domain)
    ? JSON.parse(getCookie(ctx, COOKIES.shop101Session, domain)).userId
    : 0;
};

const authToken = (ctx?: Partial<NextPageContext> | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || '') : '';
  const authTk = getCookie(ctx, COOKIES.shop101Session, domain)
    ? JSON.parse(getCookie(ctx, COOKIES.shop101Session, domain))?.authToken
    : '';
  try {
    if (!authTk) {
      throw new Error(
        `Auth token is empty for userId - ${JSON.parse(getCookie(ctx, COOKIES.shop101Session, domain) || '{}')?.userId}`
      );
    }
  } catch (e) {
    captureException(e);
  }
  return authTk;
};

// const authToken = () => '22564a6425d963ecacb965e8ffa09afde339c0995c5597082c454c9f8d4a2698eb54608929a9fee5328287a31da7f0e5e206ea44ffd4735bacd8c18bd1ba12d82W/LyWzUWfDS0h2Tm9odvQ==';

const sellerStoreId = (ctx?: Partial<NextPageContext> | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || '') : '';
  const storeId = getCookie(ctx, COOKIES.shop101Session, domain)
    ? JSON.parse(getCookie(ctx, COOKIES.shop101Session, domain)).storeId
    : 0;
  try {
    if (!storeId) {
      throw new Error(
        `Store id is empty for userId - ${JSON.parse(getCookie(ctx, COOKIES.shop101Session, domain) || '{}')?.userId} and auth token - ${JSON.parse(getCookie(ctx, COOKIES.shop101Session, domain) || '{}')?.authToken}`
      );
    }
  } catch (e) {
    captureException(e);
  }
  return storeId;
};

const userEmail = (ctx?: Partial<NextPageContext> | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || '') : '';
  return getCookie(ctx, COOKIES.shop101Session, domain)
    ? JSON.parse(getCookie(ctx, COOKIES.shop101Session, domain)).userEmail
    : '';
};

const isStoreCreated = (ctx?: Partial<NextPageContext> | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || '') : '';
  return getCookie(ctx, COOKIES.shop101Session, domain)
    ? JSON.parse(getCookie(ctx, COOKIES.shop101Session, domain)).isStoreCreated
    : '';
};

// const sellerStoreId = () => '6031360120';

const deviceKey = (ctx?: Partial<NextPageContext> | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || '') : '';
  return getCookie(ctx, COOKIES.shop101DeviceKey, domain)
    ? getCookie(ctx, COOKIES.shop101DeviceKey, domain)
    : 0;
};

const internalUserEmail = (
  ctx?: Partial<NextPageContext> | null | undefined
) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || '') : '';
  return getCookie(ctx, COOKIES.shop101InternalPanelSession, domain)
    ? JSON.parse(getCookie(ctx, COOKIES.shop101InternalPanelSession, domain))
        .email
    : 0;
};

const isKAM = (ctx?: Partial<NextPageContext> | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || '') : '';
  return getCookie(ctx, COOKIES.shop101Session, domain)
    ? JSON.parse(getCookie(ctx, COOKIES.shop101Session, domain)).isKAM
    : '';
};

export {
  isUserLoggedIn,
  userId,
  authToken,
  sellerStoreId,
  deviceKey,
  userEmail,
  internalUserEmail,
  isStoreCreated,
  isKAM,
};
