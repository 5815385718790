import React from 'react';
import MenuItem from '@/components/Sidebar/MenuItem';
import SupportIcon from '@/components/vectors/SupportIcon';
import { useRouter } from 'next/router';
import AccountIcon from '@/components/vectors/AccountIcon';
import FaqIcon from '../vectors/FaqIcon';
import ConfirmationModal from '@/components/ConfirmationModal';

interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = () => {
  const router = useRouter();
  const activePath = React.useMemo(() => router.asPath, [router.asPath]);
  const [showTnCModal, setShowTnCModal] = React.useState(false);
  return (
    <>
      <div className='relative h-[calc(100%_-_60px)] w-[260px] overflow-hidden bg-base-200 shadow-[inset_-2px_0_2px_rgba(0,0,0,0.1)]'>
        <div className='h-full overflow-y-scroll'>
          <div className='w-[260px] overflow-y-scroll bg-base-200 shadow-[inset_-2px_0_2px_rgba(0,0,0,0.1)]'>
            <ul className='menu mb-10 w-full px-2.5 py-5 text-base-content'>
              <MenuItem
                isActive={activePath.includes('/profile')}
                icon={
                  <AccountIcon
                    width={'28'}
                    height={'28'}
                  />
                }
                text='Profile'
                link={'/profile'}
                isNew
              />
              <MenuItem
                icon={<FaqIcon />}
                text='FAQs'
                link={'/assets/Faq-On-Gst.pdf'}
                target={'_blank'}
                isNew
              />
              {/*<MenuItem icon={<FaqIcon/>}*/}
              {/*          text="FAQs"*/}
              {/*          link={'/seller/supplier-faq'}/>*/}
              <MenuItem
                icon={<SupportIcon />}
                text='Support'
                link={process.env.NEXT_PUBLIC_FRESHDESK_URL}
                target={'_blank'}
              />
            </ul>
          </div>
          <div className='absolute bottom-0 left-0 w-[99%] w-full self-end bg-base-200 px-[10px] py-[22px] text-center text-xs text-neutral'>
            <a
              className='cursor-pointer underline'
              onClick={() => setShowTnCModal(true)}
            >
              <b>T&C</b> and <b>Privacy Policy</b> links
            </a>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title='TnC and Privacy Policy links'
        isOpen={showTnCModal}
        closeModal={() => setShowTnCModal(false)}
        hideFooter
        content={
          <div className='w-[600px] px-3 text-center'>
            <div>
              Roposo B2C{' '}
              <a
                className='font-semibold underline'
                target='_blank'
                href='https://www.shop101.com/static/termsOfService.html'
                rel='noreferrer'
              >
                T&C
              </a>{' '}
              and{' '}
              <a
                className='font-semibold underline'
                target='_blank'
                href='https://www.shop101.com/static/privacyPolicy.html'
                rel='noreferrer'
              >
                Privacy Policy
              </a>
              .
            </div>
            <div>
              Roposo Clout for Business{' '}
              <a
                className='font-semibold underline'
                target='_blank'
                href='https://www.shop101.com/static/b2bTermsOfService.html'
                rel='noreferrer'
              >
                T&C
              </a>
            </div>
            <div>
              Roposo B2C{' '}
              <a
                className='font-semibold underline'
                target='_blank'
                href='https://www.shop101.com/static/b2cSupplierGuidelines.html'
                rel='noreferrer'
              >
                Supplier Guidelines
              </a>
            </div>
          </div>
        }
      />
    </>
  );
};

export default Sidebar;
