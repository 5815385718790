const AnnouncementIcons = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.25 7.5V19.0969C14.2489 19.2215 14.2792 19.3444 14.3382 19.4542C14.3972 19.564 14.4829 19.6572 14.5875 19.725L15.6188 20.4094C15.7187 20.4761 15.8331 20.5178 15.9525 20.531C16.0719 20.5443 16.1927 20.5287 16.3048 20.4855C16.4169 20.4423 16.517 20.3728 16.5966 20.2829C16.6763 20.193 16.7332 20.0852 16.7625 19.9688L18 15'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 15C18.9946 15 19.9484 14.6049 20.6517 13.9017C21.3549 13.1984 21.75 12.2446 21.75 11.25C21.75 10.2555 21.3549 9.30164 20.6517 8.59838C19.9484 7.89511 18.9946 7.50003 18 7.50003H14.25C14.25 7.50003 9.14062 7.50003 4.22812 3.3844C4.11886 3.29398 3.9862 3.2364 3.84553 3.21833C3.70486 3.20026 3.56195 3.22245 3.43338 3.28232C3.30481 3.34219 3.19585 3.43728 3.11914 3.55657C3.04244 3.67586 3.00112 3.81446 3 3.95628V18.5438C3.00112 18.6856 3.04244 18.8242 3.11914 18.9435C3.19585 19.0628 3.30481 19.1579 3.43338 19.2177C3.56195 19.2776 3.70486 19.2998 3.84553 19.2817C3.9862 19.2637 4.11886 19.2061 4.22812 19.1157C9.14062 15 14.25 15 14.25 15H18Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default AnnouncementIcons;
