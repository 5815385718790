import { useEffect, useRef, useState, useMemo, cloneElement } from 'react';
import Header from '@/components/Header';
import Sidebar from '@/components/Sidebar';
import { LayoutProvider, useLayoutContext } from './provider';
import withHOC from '@/utils/with-hoc';
import pageErrorHandler from '@/components/PageErrorHandler';
import ToastContainer from '@/components/ToastContainer/ToastContainerV2';
import { useRouter } from 'next/router';
import { themeChange } from 'theme-change';
import AnnouncementModal from '@/components/AnnouncementModal';
import dynamic from 'next/dynamic';
import StoreIncompleteSidebar from '@/components/StoreIncompleteSidebar';
import { isUserLoggedIn } from '@/utils/auth';

const RedAlert = dynamic(() => import('components/RedAlert'));

interface LayoutProps {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  props: {
    isStoreCreationIncomplete: boolean;
    productSummary: {
      supplierRating: number;
    };
    storeResponse: any;
    isMobile: boolean;
    context404: string | null;
    statusCode: number | null;
  };
}

//create type definition for Layout

const Layout = ({ children, props }: LayoutProps) => {
  // const {productSummary, storeResponse} = props;
  const { ordersCount, newOrdersInfo, setNewOrdersInfo } = useLayoutContext();
  const router = useRouter();
  const currentRoute = useMemo(() => router.pathname, [router.pathname]);
  const [isAnnouncementModalOpen, setIsAnnouncementModalOpen] = useState(false);
  const announcementsRef = useRef<any>();
  const markAsReadCallbackRef = useRef<any>(null);

  const openAnnouncementModal = (
    list: any[],
    markAllAnnouncementsAsRead?: Function
  ) => {
    if (list?.length > 0) {
      announcementsRef.current = list;
      markAsReadCallbackRef.current = markAllAnnouncementsAsRead;
      setIsAnnouncementModalOpen(true);
    }
  };

  const closeAnnouncementModal = () => {
    if (announcementsRef.current?.length > 0) {
      markAsReadCallbackRef.current?.(announcementsRef.current?.[0]?.postId);
    }
    setIsAnnouncementModalOpen(false);
  };

  useEffect(() => {
    // localStorage.setItem("theme", "lofi");
    themeChange(false);
    // 👆 false parameter is required for react project
  }, []);

  const hasWindow = typeof window !== 'undefined';

  if (
    currentRoute === '/login' ||
    currentRoute === '/signup' ||
    currentRoute.includes('/verification') ||
    (hasWindow && !isUserLoggedIn())
  ) {
    return (
      <div className='h-full'>
        {children}
        <ToastContainer position='bottom-center' />
      </div>
    );
  }
  return (
    <div className='h-full'>
      <Header
        supplierRating={props?.productSummary?.supplierRating}
        storeName={props?.storeResponse?.store?.storeName}
        isMobile={props?.isMobile}
      />
      <div className='drawer drawer-mobile relative h-full'>
        <input
          id='sidebar'
          type='checkbox'
          className='drawer-toggle'
        />
        <div
          id='scrollableDiv'
          className='drawer-content !z-auto h-full'
        >
          <RedAlert
            message="⚠️ We'll be undergoing temporary maintenance on Friday, 23rd Feb 2024, from 1:00 AM to 2:00 AM. We appreciate your understanding."
            expiryDateTime='2024-02-23 02:00:00'
          />
          {children &&
            cloneElement(children, {
              ordersCount,
              newOrdersInfo,
            })}
        </div>
        <div className='drawer-side'>
          <label
            htmlFor='sidebar'
            className='drawer-overlay'
          ></label>
          {props.isStoreCreationIncomplete ? (
            <StoreIncompleteSidebar />
          ) : (
            <Sidebar
              openAnnouncementModal={openAnnouncementModal}
              newOrdersInfo={newOrdersInfo}
              setNewOrdersInfo={setNewOrdersInfo}
            />
          )}
        </div>
      </div>
      <ToastContainer position='bottom-center' />

      {!props.isStoreCreationIncomplete && (
        <AnnouncementModal
          announcements={announcementsRef.current}
          isOpen={isAnnouncementModalOpen}
          closeModal={closeAnnouncementModal}
        />
      )}
    </div>
  );
};

Layout.displayName = 'Layout';

export default withHOC(pageErrorHandler, withHOC(LayoutProvider, Layout));
