import generateContext, { UseGetContextValue } from '@/utils/generate-context';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { parseCookies } from 'nookies';
import COOKIE_NAMES from '../../constants/cookies';
import { AxiosError, AxiosResponse } from 'axios';
import useAsync from '@/hooks/use-async';
import SuppliersApis from '@/apis/suppliers';
import { useRouter } from 'next/router';
import { isUserLoggedIn } from '@/utils/auth';

export const useLayout = () => {
  const [
    userInfoFromShop101SessionCookie,
    setUserInfoFromShop101SessionCookie,
  ] = useState({});

  const [ordersCount, setOrdersCountData] = useState<any>({
    roposoPendingCount: 0,
    roposoPendingProcessingCount: 0,
    roposoReadyToShipCount: 0,
    roposoToBeDispatchedCount: 0,
    roposoUnderProcessingCount: 0,
    shipstreakPendingCount: 0,
    shipstreakPendingProcessingCount: 0,
    shipstreakReadyToShipCount: 0,
    shipstreakToBeDispatchedCount: 0,
    shipstreakUnderProcessingCount: 0,
  });
  const [newOrdersInfo, setNewOrdersInfo] = useState(null);

  const getOrdersCountQuery = useAsync(SuppliersApis.getOrdersCountApi);

  const getOrderCount = useCallback(() => {
    getOrdersCountQuery.execute(
      {},
      {
        onSuccess: (res: AxiosResponse<any>) => {
          if (res.status === 200) {
            const { roposoPendingCount = 0, shipstreakPendingCount = 0 } =
              res?.data || {};

            const orderCountMeta: any = {
              roposoOrders: roposoPendingCount,
              shipstreakOrders: shipstreakPendingCount,
            };

            setNewOrdersInfo({ ...orderCountMeta });
            setOrdersCountData(res?.data || {});
          }
        },
        onError: (err: AxiosError<any>) => {
          return err.response;
        },
      }
    );
  }, []);

  useEffect(() => {
    const cookies = parseCookies();
    const userInfo = cookies[COOKIE_NAMES.SHOP101_SESSION];
    const parsedUserInfo = userInfo ? JSON.parse(userInfo) : {};
    setUserInfoFromShop101SessionCookie(parsedUserInfo);
  }, []);

  const router = useRouter();
  const packageStatus = router.query?.package?.toString() || '';

  //this is for the setting the orderState
  useEffect(() => {
    let apiCallInteval: number | NodeJS.Timeout = 0;
    if (isUserLoggedIn()) {
      getOrderCount();
      apiCallInteval = setInterval(() => getOrderCount(), 100000);
    }
    return () => {
      clearInterval(apiCallInteval);
    };
  }, [packageStatus]);

  return useMemo(() => {
    return {
      userInfoFromShop101SessionCookie,
      ordersCount,
      newOrdersInfo,
      setNewOrdersInfo,
    };
  }, [userInfoFromShop101SessionCookie, ordersCount, newOrdersInfo]);
};

export const [LayoutProvider, useLayoutContext] = generateContext(
  useLayout as unknown as UseGetContextValue
);
