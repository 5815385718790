export enum ListingStatuses {
  new = 'new',
  oos = 'oos',
}
export enum PackageStatus {
  roposo = 'Roposo',
  shipstreak = 'ShipStreak',
}
export enum OrderStatuses {
  newpending = 'newpending',
  underprocessing = 'underprocessing',
  toBeDispatched = 'toBeDispatched',
  readyToShip = 'readyToShip',
  shipped = 'shipped',
  delivered = 'delivered',
  returned = 'returned',
  cancelled = 'cancelled',
  all = 'all',
  hold = 'hold',
}

export enum RtoReturnStatuses {
  overview = 'overview',
  returnrtoshipped = 'returnrtointransit',
  returnrtooutfordelivery = 'returnrtooutfordelivery',
  returnrtodelivered = 'returnrtodelivered',
}

export enum RtoSuborderStatuses {
  returntoseller = 'returntoseller',
  reversepickupapproved = 'reversepickupapproved',
  reversepickupdone = 'reversepickupdone',
  returntosellerinitiated = 'returntosellerinitiated',
  reversepickupshipment = 'reversepickupshipment',
  returndelivered = 'returndelivered',
  canceled = 'canceled',
  delivered = 'delivered',
}

export enum PaymentStatuses {
  pastpayments = 'pastpayments',
  upcomingpayments = 'upcomingpayments',
}

export enum SettingsStatuses {
  shippinglabel = 'shippinglabel',
  autoshipment = 'autoshipment',
  pickupaddress = 'pickupaddress',
}

export enum ProductStatuses {
  instock = 'inStock',
  outofstock = 'outOfStock',
  inactive = 'inActive',
  approval = 'approval',
}

export enum filterKeyNameMapping {
  selectedSLAStatus = 'daysToBreach',
  selectedLogisticPartner = 'shipperId',
  selectedSkuId = 'skuId',
  selectedReturnType = 'returnType',
  startDate = 'startDate',
  endDate = 'endDate',
  deliveredStartDate = 'deliveredStartDate',
  deliveredEndDate = 'deliveredEndDate',
  labelDownloaded = 'labelDownloaded',
  manifestDownloaded = 'manifestDownloaded',
  quantityFilter = 'quantityFilter',
  lowInventory = 'lowInventory',
  selectedCategory = 'categoryId',
}
//this is the profile Provider
export enum ProfileStatuses {
  personaldetails = 'personaldetails',
  bankdetails = 'bankdetails',
  gstdetails = 'gstdetails',
}

//this is the gstImage type
export enum GSTImageTypes {
  PAN = 'GST_PAN_CARD',
  GST = 'GST_CERTIFICATE',
}

export default {
  ListingStatuses,
  OrderStatuses,
  RtoReturnStatuses,
  RtoSuborderStatuses,
  PaymentStatuses,
  SettingsStatuses,
  ProductStatuses,
  GSTImageTypes,
};
