/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';
import Accordion from 'components/Accordion';

//Interface of NavGroupProps
interface NestedMenuItemProps {
  navGroup: any;
  showFullWidthNavbar: boolean;
  metaInfoRenderer?: Function;
  menuItemClick?: Function;
}
const commonChildNavClass =
  'flex items-center pl-[10px] mb-[10px] ml-0 hover:bg-white hover:font-semibold  text-xs gap-[10px] transition-all duration-200 ease h-[34px]';
const activeChildNavClass =
  'font-semibold text-black hover:bg-white cursor-pointer mx-[10px] border border-solid border-gray-230 border-left-[20px]';
const unactiveClildNavClass =
  'bg-gray-200 mx-[10px] border border-solid border-gray-100 rounded-[4px] hover:border-l-[4px] hover:border-l-black  cursor-pointer';

const NestedMenuItem: React.FC<NestedMenuItemProps> = ({
  navGroup: nav,
  showFullWidthNavbar = true,
  metaInfoRenderer,
  menuItemClick,
}) => {
  const router = useRouter();
  const activeChildNavId =
    (nav.childNavs || []).find((c: any) => router.asPath.includes(c.id))?.id ||
    '';
  return (
    <>
      <div
        className={`${commonChildNavClass} !pl-[16px] ${activeChildNavId ? activeChildNavClass : ''} ${showFullWidthNavbar ? 'hidden' : ''}`}
      >
        <div className='h-[24px] min-h-[24px] w-[24px] min-w-[24px]'>
          <Image
            width={28}
            height={28}
            src={nav.icon || '/'}
            alt={nav.navId}
            loading='eager'
          />
        </div>
      </div>
      <Accordion
        key={activeChildNavId}
        open={!!activeChildNavId}
        wrapperClass={`mb-[10px] last:mb-0 rounded-[5px] !gap-[8px] !w-[98.6%] border-l-transparent hover:bg-white ${activeChildNavId ? 'bg-white' : ''} ${!showFullWidthNavbar ? 'hidden' : ''}`}
        openWrapperClass='bg-white'
        containerClass='!justify-start !items-center'
        renderHeader={
          <div
            id={nav.navId}
            className='flex items-center justify-between pl-[7px] pr-[10px]'
          >
            <div className='h-[24px] min-h-[24px] w-[24px] min-w-[24px]'>
              {/* <Image width={24} height={24} src={nav.icon || "/"} alt={nav.navId} loading="eager"/> */}
              <span>{nav.icon}</span>
            </div>
            <div className={`ml-[4px] whitespace-nowrap`}>
              <span className='text-sm'>{nav.label}</span>
            </div>
          </div>
        }
        renderContent={
          <React.Fragment>
            {(nav.childNavs || []).map((navItem: any) => (
              <Link
                key={navItem.label}
                href={navItem.link || '/'}
                passHref
                prefetch={false}
                legacyBehavior
                id={navItem.navId}
              >
                <div
                  className={`ml-[10px] rounded-[4px] ${navItem.id === activeChildNavId ? 'border-l-[4px] border-black' : ''}`}
                  onClick={() => menuItemClick?.(navItem.navId)}
                >
                  <div
                    className={`${commonChildNavClass} ${navItem.id === activeChildNavId ? activeChildNavClass : unactiveClildNavClass}`}
                  >
                    {navItem.icon ? (
                      <Image
                        src={navItem.icon || '/'}
                        alt={navItem.navId}
                        width={21}
                        height={21}
                      />
                    ) : null}
                    <span className='whitespace-nowrap text-[14px]'>
                      {navItem.label}
                    </span>
                    {metaInfoRenderer?.(navItem.navId) || null}
                  </div>
                </div>
              </Link>
            ))}
          </React.Fragment>
        }
      />
    </>
  );
};

NestedMenuItem.defaultProps = {
  showFullWidthNavbar: true,
};

export default NestedMenuItem;
