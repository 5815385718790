import React, { ComponentType } from 'react';

type ProviderType = ComponentType<any>;
type WrappedComponentType = ComponentType<any>;

export default function withHOC(
  Provider: ProviderType,
  Component: WrappedComponentType
): WrappedComponentType {
  return function WrappedComponent(
    props: any
  ): React.ReactElement<any, any> | null {
    return (
      <Provider {...props}>
        <Component {...props} />
      </Provider>
    );
  };
}
