import axios, { ResponseType } from 'axios';
import { NextPageContext } from 'next';
import { setGlobalConfig } from 'axios-logger';
import { authToken, deviceKey } from 'utils/auth';
import { COOKIES, deleteCookie, getCookieDomain } from '@/services/cookies';
import { toast } from 'react-toastify';
import logger from 'utils/logger';

interface ApiCallParams {
  method: 'get' | 'put' | 'post' | 'delete';
  ctx?: Partial<NextPageContext> | null;
  url: string;
  data?: any;
  headers?: { [key: string]: string };
  responseType?: ResponseType;

  callback?: (response: any) => void;
  errorCallback?: (error: any) => void;

  params?: object;
}

setGlobalConfig({
  headers: true,
  data: false,
});

const instance = axios.create();
// const notificationModal = useNotificationModalContext();
if (typeof window === 'undefined') {
  // instance.interceptors.request.use(
  //   AxiosLogger.requestLogger as (value: InternalAxiosRequestConfig<any>) => InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>>,
  //   AxiosLogger.errorLogger
  // );
  // instance.interceptors.response.use(
  //   AxiosLogger.responseLogger,
  //   AxiosLogger.errorLogger
  // );
  instance.interceptors.request.use(
    (config) => {
      logger.info(
        `Axios Request - ${config.method?.toUpperCase()} ${config.url}`,
        {
          headers: config.headers,
          data: JSON.stringify(config.data),
        }
      );
      return config;
    },
    (error) => {
      logger.error('Axios Request Error:', { error });
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    (response) => {
      logger.info(
        `Axios Response - ${response.config.method?.toUpperCase()} ${response.config.url}`,
        {
          status: response.status,
          headers: response.headers,
          data: JSON.stringify(response.data),
        }
      );
      return response;
    },
    (error) => {
      logger.error('Axios Response Error:', { error });
      return Promise.reject(error);
    }
  );
} else {
  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error?.response?.status >= 500) {
        (async () => {
          await toast.error(
            error?.response?.data?.status || 'Something went wrong'
          );
        })();
      }
      // eslint-disable-next-line no-undef
      return Promise.reject(error);
    }
  );
}

const publicApiUrls = [/sessions\/supplierSignIn/, /OTP\/user/];

export const call = ({
  url,
  method,
  data,
  ctx,
  headers,
  responseType,
  params,
}: ApiCallParams) => {
  const responseType_ = responseType || 'json';
  let headers_ = {
    'X-Auth-Token': publicApiUrls.some((rx) => rx.test(url))
      ? ''
      : authToken(ctx),
    'X-DeviceId': publicApiUrls.some((rx) => rx.test(url))
      ? ''
      : deviceKey(ctx),
    'X-Forwarded-For': ctx?.req?.headers['X-Forwarded-For'],
    // 'X-Real-IP': ctx?.req?.headers['X-Real-IP'],
    'User-Agent': ctx?.req?.headers['user-agent'],
    Referer: ctx?.req?.headers['referer'],
    // 'Host': apiDetails.req.get('realhost') || apiDetails.req.get('host'),
    // 'Original-Host': ctx?.req?.headers['realhost'] || ctx?.req?.headers['host'],
    // 'Original-Host': 'www.zyxw365.in',
    'X-App-Version': process.env.NEXT_PUBLIC_APP_VERSION,
    'X-Device-Type': 'web',
    // 'X-Custom-Domain': activeConfig.selectedEnv !== 'local' && urlHost.indexOf('localhost') === -1 && urlHost.indexOf('zyxw365') === -1 && urlHost.indexOf('shop101') === -1
  };
  if (!headers_['X-Auth-Token']) {
    delete headers_['X-Auth-Token'];
  }
  if (headers) {
    headers_ = Object.assign(headers_, headers);
  }
  return instance({
    method,
    url,
    data,
    headers: headers_,
    responseType: responseType_,
    params: params,
  })
    .then((response: any) => {
      return response;
    })
    .catch((e) => {
      if (e?.response?.status === 401) {
        // removes domain specific cookies as well
        deleteCookie(ctx, COOKIES.shop101Session);
        deleteCookie(
          ctx,
          COOKIES.shop101Session,
          getCookieDomain(ctx?.req?.headers?.host || '')
        );
      }
      throw e;
    });
};

export const paramsToString = (paramsObj: any) => {
  return new URLSearchParams(paramsObj).toString();
};

export default {
  call,
};
